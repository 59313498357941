<template>
  <v-autocomplete
        :value="Value"
        :rules="Rules"
        :label="Label"
        :items="Items"
        item-text="text" 
        item-value="value"
        multiple
        clearable
        outlined
        :chips='Chips'
        @click:append="GetValues()"
        @change="Fn($event)"
        @click="GetValues()"                             
    ></v-autocomplete>
</template>

<script>
export default {
    props:{
        Value: Array,          //
        Rules: Array,           //Rules for input's text
        Items: Array,           //Are the array items
        Label: String,          //Is the input's name
        Fn: Function,           //Funtion to do when the users change the input's value
        GetValues:  Function,   //Is used to get the items
        Chips:  Boolean
    },
}
</script>

<style>

</style>